import React from 'react';
import styles from './CurrentlyPlayingCard.module.scss';
import CardImage from 'next/image';
import { loaderUrl, squareImageLoader } from 'utils/cloudinary';
import { Card, Pointer } from '@yleisradio/areena-types';
import { BackgroundImage } from 'components/Player/AudioPlayerWrapper/ExpandedAudioPlayer/BackgroundImage';
import { getProgramTitleWithStartTime } from 'utils/card';
import { Link } from 'components/Link';

type Props = {
  ongoingCard: Card | undefined;
  upcomingCard: Card | undefined;
  isRadioChannel: boolean;
  onClick: (() => void) | undefined;
};

const CardLink = ({
  pointer,
  children,
  onClick,
}: {
  pointer: Pointer | undefined;
  children: React.ReactNode;
  onClick: (() => void) | undefined;
}) => {
  if (!pointer) {
    return <div className={styles.card}>{children}</div>;
  }
  return (
    <Link pointer={pointer} className={styles.card} onClick={onClick}>
      {children}
    </Link>
  );
};

export const CurrentlyPlayingCard = ({
  ongoingCard,
  upcomingCard,
  isRadioChannel,
  onClick,
}: Props) => {
  const { title, description, image, pointer } = ongoingCard || {};

  const ongoingTitle = getProgramTitleWithStartTime(ongoingCard);
  const upcomingTitle = getProgramTitleWithStartTime(upcomingCard);

  const primaryText = isRadioChannel ? ongoingTitle : title;
  const secondaryText = isRadioChannel ? upcomingTitle : description;

  return (
    <div>
      <BackgroundImage image={image} />
      <CardLink pointer={pointer} onClick={onClick}>
        <div className={styles.imageContainer}>
          {image && (
            <div className={styles.imageWrapper}>
              <CardImage
                src={loaderUrl(image)}
                alt=""
                fill
                role="presentation"
                sizes={'(min-width: 640px) 44vw, 100vw'}
                loader={squareImageLoader}
                className={styles.image}
              />
            </div>
          )}
        </div>

        <div className={styles.textContainer}>
          <p className={styles.primaryText}>{primaryText || ''}</p>
          <p className={styles.secondaryText}>{secondaryText || ''}</p>
        </div>
      </CardLink>
    </div>
  );
};
