// These class names are defined in styles/global.scss
const scrollPaddingClassName = 'isAudioPlayerVisible';
const preventBodyScrollClassName = 'preventScroll';

export const enableScrollPadding = () => {
  document.documentElement.classList.add(scrollPaddingClassName);
};

export const disableScrollPadding = () => {
  document.documentElement.classList.remove(scrollPaddingClassName);
};

export const enableBodyScroll = () => {
  document.body.classList.remove(preventBodyScrollClassName);
};

export const disableBodyScroll = () => {
  document.body.classList.add(preventBodyScrollClassName);
};
