import { useState } from 'react';
import { progressToString } from 'utils/time';

const usePointerMoveOnProgressBar = (
  barContainerElement: HTMLDivElement | null
) => {
  const [relativePointerPosition, setRelativePointerPosition] = useState(0);
  const [isPointerOnBar, setIsPointerOnBar] = useState(false);

  const pointerPositionHandler = (
    event: React.PointerEvent<HTMLDivElement>
  ) => {
    const containerRect = barContainerElement?.getBoundingClientRect();
    const containerWidth = containerRect?.width || 0;
    const containerX = containerRect?.x || 0;
    const position = (event.clientX - containerX) / containerWidth;
    setRelativePointerPosition(position);
  };
  const pointerEnterHandler = () => setIsPointerOnBar(true);
  const pointerLeaveHandler = () => setIsPointerOnBar(false);

  const getPointerPosition = () =>
    Math.min(Math.max(0, relativePointerPosition), 1);

  const getPointerTime = (duration: number) =>
    progressToString(
      Math.floor(duration * Math.min(Math.max(0, relativePointerPosition), 1))
    );

  return {
    pointerPositionHandler,
    pointerEnterHandler,
    pointerLeaveHandler,
    getPointerPosition,
    getPointerTime,
    isPointerOnBar,
  };
};

export default usePointerMoveOnProgressBar;
