import React from 'react';
import styles from './ToggleQueueCardsPrompt.module.scss';
import { Button } from 'components/Button';
import { useTranslation } from 'hooks/useTranslation';

type Props = {
  updateItems: (operationType: 'add' | 'remove') => void;
  isRemoveDisabled: boolean;
};

export const ToggleQueueCardsPrompt: React.FunctionComponent<Props> = ({
  updateItems,
  isRemoveDisabled = false,
}) => {
  const t = useTranslation();

  return (
    <div className={styles.toggleQueuePrompt}>
      <Button
        text={t('addToQueueButton')}
        onClick={() => {
          updateItems('add');
        }}
        size={'xs'}
        variant={'primary'}
        padding={'default'}
      />
      <Button
        text={t('removeFromQueueButton')}
        onClick={() => {
          updateItems('remove');
        }}
        size={'xs'}
        variant={'primary'}
        padding={'default'}
        disabled={isRemoveDisabled}
      />
    </div>
  );
};
