import { useTranslation } from 'hooks/useTranslation';
import Icon from 'assets/close.svg';
import styles from './ClosePlayerButton.module.scss';
type CloseProps = {
  closePlayer: () => void;
};

const ClosePlayerButton = ({ closePlayer }: CloseProps) => {
  const t = useTranslation();
  return (
    <button
      aria-label={t('closePlayer')}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closePlayer();
      }}
      className={styles.closeIcon}
    >
      <Icon />
    </button>
  );
};

export default ClosePlayerButton;
