import React from 'react';
import styles from './Controls.module.scss';
import { ButtonPlayerControl } from './ButtonPlayerControl';
import { PlayerProps } from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';

const PlayerControls = ({
  forward10,
  isPlayerExpanded,
  isPlaying,
  replay10,
  togglePlay,
  previous,
  next,
  hasNext,
  hasPrevious,
  isRadioChannel,
}: PlayerProps) => {
  return (
    <div className={styles.controlGroup}>
      {!isRadioChannel && (
        <ButtonPlayerControl
          onClick={previous}
          kind={'previous'}
          disabled={!hasPrevious}
        />
      )}
      {!isRadioChannel && (
        <ButtonPlayerControl
          onClick={replay10}
          kind={'replay10'}
          isButtonLarge={isPlayerExpanded}
        />
      )}
      <ButtonPlayerControl
        onClick={togglePlay}
        kind={isPlaying ? 'pause' : 'play'}
        isButtonLarge={isPlayerExpanded}
        focusOnMount={!isPlayerExpanded}
      />
      {!isRadioChannel && (
        <ButtonPlayerControl
          onClick={forward10}
          kind={'forward10'}
          isButtonLarge={isPlayerExpanded}
        />
      )}
      {!isRadioChannel && (
        <ButtonPlayerControl onClick={next} kind={'next'} disabled={!hasNext} />
      )}
    </div>
  );
};

export default PlayerControls;
