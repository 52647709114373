import styles from './NextInRecommendationsList.module.scss';
import { Card, ListStyle } from '@yleisradio/areena-types';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { getCardKey } from 'utils/card';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { Labels } from 'services/yleAnalyticsSdk';
import { QUEUE_PAGE_SIZE } from 'contexts/PlayerStateContext';
import { useTranslation } from 'hooks/useTranslation';
import { Toggle } from './Toggle';
import { idFromPointer } from 'utils/pointer';

type Props = {
  heading: string;
  cards: Card[];
  analyticsContext: Labels | undefined;
  listKey: string;
  loading?: boolean;
  showRecommendationsQueue: boolean;
  setShowRecommendationsQueue: (value: boolean) => void;
};

const listStyle: ListStyle = {
  layout: 'vertical',
  image: '1:1',
  size: 'medium',
};

const NextInRecommendationsList = ({
  heading,
  cards,
  analyticsContext,
  listKey,
  loading = false,
  showRecommendationsQueue,
  setShowRecommendationsQueue,
}: Props) => {
  const t = useTranslation();
  const toggleInputId = 'recommendations-queue-toggle';

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <div className={styles.nextInListHeader}>
        <div className={styles.headerTexts}>
          <h3 className={styles.subTitle}>
            <label htmlFor={toggleInputId}>{heading}</label>
          </h3>
          <div className={styles.recommendationsInfoText}>
            {t('recommendationsQueueInfoText')}
          </div>
        </div>
        <div className={styles.toggleWrapper}>
          <Toggle
            id={toggleInputId}
            checkedValue={showRecommendationsQueue}
            onCheckedChangeFunction={setShowRecommendationsQueue}
          />
        </div>
      </div>
      {showRecommendationsQueue &&
        cards &&
        cards.map((card) => {
          const cardKey = getCardKey(card, listKey);
          const itemId = idFromPointer(card?.pointer) || '';
          const item = {
            key: `recommendations-${cardKey}`,
            programId: itemId,
          };
          return (
            <div key={cardKey} className={styles.cardContainer}>
              <PlayQueueCard
                item={item}
                isActive={false}
                setCheckboxSelected={() => {}}
                isCheckboxSelected={false}
              />
            </div>
          );
        })}

      {loading &&
        Array.from({ length: QUEUE_PAGE_SIZE }, (_, i) => (
          <li key={i} aria-hidden>
            <PlaceholderCard listStyle={listStyle} />
          </li>
        ))}
    </AnalyticsContextProvider>
  );
};

export default NextInRecommendationsList;
