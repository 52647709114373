import { usePlayerState } from 'contexts/PlayerStateContext';
import { useUILanguage } from 'hooks/useUILanguage';
import useSWR from 'swr';
import { Language, LastPlayedSong as Song } from '@yleisradio/areena-types';
import { fetchLastPlayedSongs } from 'services/areena-api/fetchers';

type RequestKey = [
  'last-played-songs',
  currentlyPlaying: string | undefined,
  language: Language,
];

const fetcher: (args: RequestKey) => Promise<Song[] | undefined> = async ([
  ,
  serviceId,
  language,
]) => {
  if (!serviceId) return;
  const response = await fetchLastPlayedSongs(serviceId, language);

  return response?.data;
};

export const useFetchLastPlayedSongs = () => {
  const { currentlyPlayingId } = usePlayerState();
  const language = useUILanguage();
  const key: RequestKey = ['last-played-songs', currentlyPlayingId, language];

  const { data } = useSWR(key, fetcher, {
    refreshInterval: 300000,
  });

  return data;
};
