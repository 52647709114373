import { useRouter } from 'next/router';
import {
  ylePlayerScriptUrl,
  ylePlayerStagingScriptUrl,
} from 'services/properties/frontend';

export function usePlayerScriptUrl(): string {
  const router = useRouter();

  return 'stagingPlayer' in router.query
    ? ylePlayerStagingScriptUrl
    : ylePlayerScriptUrl;
}
