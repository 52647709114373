import { Card, Language } from '@yleisradio/areena-types';
import { useUILanguage } from 'hooks/useUILanguage';
import useSWR from 'swr';
import { fetchQueueCard } from 'services/areena-api/fetchers';

type Key = [itemId: string, language: Language];

const fetcher: (args: Key) => Promise<Card | undefined> = async ([
  itemId,
  language,
]) => {
  const response = await fetchQueueCard(itemId, language);

  return response?.data;
};

export const useFetchQueueCard = (
  programId: string | undefined
): Card | undefined => {
  const language = useUILanguage();

  const { data } = useSWR(
    programId ? ([programId, language] satisfies Key) : null,
    fetcher
  );

  return data;
};
