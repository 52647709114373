import { Language, Control as ControlType } from '@yleisradio/areena-types';
import { ControlPresentation } from 'components/Controls/Control/ControlPresentation';
import { useControl } from 'components/Controls/Control/useControl';
import { useTranslation } from 'hooks/useTranslation';
import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';
import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';

const loginControlTitle = {
  fi: 'Kirjaudu sisään',
  sv: 'Logga in',
};

const loginControl: (language: Language) => ControlType = (
  language: Language
) => {
  const control: ControlType = {
    type: 'activator',
    tag: 'login',
    functionality: {
      id: 'login',
    },
    title: loginControlTitle[language],
  };
  return control;
};

const LoginPromotion = () => {
  const language = useUILanguage();
  const { control, action } = useControl({
    control: loginControl(language),
  });
  const t = useTranslation();
  return (
    <div>
      <p className={styles.loginToUseText}>{t('loginToUseQueue')}</p>
      {control ? (
        <ControlPresentation
          control={control}
          onClick={() => {
            if (action) action();
          }}
          as="button"
          buttonSize="sm"
          buttonVariant="secondary"
        />
      ) : null}
    </div>
  );
};

export default LoginPromotion;
