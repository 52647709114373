import CloseIcon from 'assets/close.svg';
import { Button } from 'components/Button';
import { useTranslation } from 'hooks/useTranslation';
import React from 'react';
import styles from './ExpandedAudioPlayer.module.scss';
import { CurrentlyPlayingCard } from './CurrentlyPlayingCard';
import { PlayerProps } from '../typesAudioPlayerWrapper';
import PlayerControls from 'components/Player/AudioPlayerWrapper/PlayerControls';
import ProgressBar from '../ProgressBar/ProgressBar';
import dynamic from 'next/dynamic';
import PopOverButton from 'components/Player/AudioPlayerWrapper/PopOverButton';
import MoreDropdownMenu from 'components/Player/AudioPlayerWrapper/MoreDropdownMenu';
import { getBroadcastDates } from 'utils/card';
import { PlayQueue } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue';
import { LastPlayedSongsList } from 'components/Player/AudioPlayerWrapper/LastPlayedSongs/LastPlayedSongsList';
import SongListIcon from 'assets/songListIcon.svg';
import PlayQueueIcon from 'assets/playQueueIcon.svg';
import { Dialog, DialogTitle } from 'components/Dialog/Dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

type Props = PlayerProps & {
  minimizePlayer(): void;
};

const PopoverWrapper = dynamic(() =>
  import('components/Player/AudioPlayerWrapper/PopoverWrapper').then(
    (module) => module.PopoverWrapper
  )
);

export const ExpandedAudioPlayer: React.FunctionComponent<Props> = (props) => {
  const {
    isPlayerExpanded,
    minimizePlayer,
    seek,
    progression,
    duration,
    isPopoverOpen,
    closePopover,
    replay10,
    forward10,
    togglePopover,
    getPlaybackRates,
    getPlaybackRate,
    setPlaybackRate,
    ongoingCard,
    upcomingCard,
    isRadioChannel,
  } = props;

  const playerRef = React.useRef<HTMLDivElement>(null);
  const t = useTranslation();

  const { broadcastStartDate, broadcastEndDate } = getBroadcastDates(
    ongoingCard?.labels
  );

  return (
    <Dialog
      isOpen={isPlayerExpanded}
      onOpenChange={(open: boolean) => {
        if (!open) minimizePlayer();
      }}
      className={styles.root}
    >
      <VisuallyHidden asChild>
        <DialogTitle>{t('audioPlayer')}</DialogTitle>
      </VisuallyHidden>

      <div className={styles.container}>
        <CurrentlyPlayingCard
          onClick={minimizePlayer}
          ongoingCard={ongoingCard}
          upcomingCard={upcomingCard}
          isRadioChannel={isRadioChannel}
        />
        <div className={styles.minifyButtonWrapper}>
          <Button
            aria-label={t('minimizePlayer')}
            text={t('close')}
            iconRight={CloseIcon}
            padding="vertical"
            variant="text"
            onClick={minimizePlayer}
          />
        </div>

        <div className={styles.controlsWrapper}>
          <ProgressBar
            progression={progression}
            duration={duration}
            seek={seek}
            playerIsExpanded={true}
            broadcastStartDate={broadcastStartDate}
            broadcastEndDate={broadcastEndDate}
          />

          <PlayerControls
            {...props}
            isPlayerExpanded={true}
            replay10={replay10}
            forward10={forward10}
          />
          <div className={styles.menuControlGroup}>
            <PopOverButton
              togglePlayQueue={togglePopover}
              Icon={isRadioChannel ? SongListIcon : PlayQueueIcon}
              buttonText={isRadioChannel ? t('songs') : t('queue')}
            />
            {!isRadioChannel && (
              <div className={styles.moreDropdownButton}>
                <MoreDropdownMenu
                  getPlaybackRates={getPlaybackRates}
                  getPlaybackRate={getPlaybackRate}
                  setPlaybackRate={setPlaybackRate}
                />
              </div>
            )}
          </div>
        </div>
        <div ref={playerRef}>
          {isPopoverOpen && (
            <PopoverWrapper close={closePopover} isOpen={isPopoverOpen}>
              {isRadioChannel ? (
                <LastPlayedSongsList />
              ) : (
                <PlayQueue ongoingCard={ongoingCard} />
              )}
            </PopoverWrapper>
          )}
        </div>
      </div>
    </Dialog>
  );
};
