import styles from './RadioChannelCardContent.module.scss';
import React from 'react';
import { Card } from '@yleisradio/areena-types';
import { getProgramTitleWithStartTime } from 'utils/card';

type Props = {
  ongoingCard: Card | undefined;
  upcomingCard: Card | undefined;
};

const RadioChannelCardContent = ({ ongoingCard, upcomingCard }: Props) => {
  const ongoingTitle = getProgramTitleWithStartTime(ongoingCard);
  const upcomingTitle = getProgramTitleWithStartTime(upcomingCard);
  return (
    <div className={styles.textContainer}>
      <p className={styles.ongoing}>{ongoingTitle}</p>
      <p className={styles.upcoming}>{upcomingTitle}</p>
    </div>
  );
};

export { RadioChannelCardContent };
