import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import { useGetProgressToText } from 'utils/time';
import { useTranslation } from 'hooks/useTranslation';
import styles from './Bar.module.scss';
import classnames from 'classnames';
import usePointerMoveOnProgressBar from './usePointerMoveOnProgressBar';
import { PointerPositionTimePreview } from './PointerPositionTimePreview';
import { PointerHoverBar } from './PointerHoverBar';

export type Props = {
  progression: number;
  duration: number;
  seek: (value: number) => void;
  playerIsExpanded: boolean;
  seekEnded: (value: number) => void;
};

export const Bar = ({
  progression,
  duration,
  seek,
  seekEnded,
  playerIsExpanded,
}: Props) => {
  const trackRef = React.useRef<HTMLDivElement>(null);

  const {
    pointerPositionHandler,
    pointerEnterHandler,
    pointerLeaveHandler,
    getPointerTime,
    isPointerOnBar,
    getPointerPosition,
  } = usePointerMoveOnProgressBar(trackRef?.current);
  const t = useTranslation();

  return (
    <>
      <PointerPositionTimePreview
        visibility={isPointerOnBar ? 'visible' : 'hidden'}
        pointerPosition={getPointerPosition()}
        pointerTime={getPointerTime(duration)}
        playerIsExpanded={playerIsExpanded}
      />
      <Slider.Root
        className={classnames(styles.barSliderRoot, {
          [`${styles.barSliderRootExpanded}`]: playerIsExpanded,
          [`${styles.barSliderRootMinimized}`]: !playerIsExpanded,
        })}
        value={[progression]}
        max={duration}
        step={1}
        aria-label={t('timeline')}
        onValueChange={(value: [number]) => seek(value[0])}
        onValueCommit={(value: [number]) => seekEnded(value[0])}
        onPointerMove={pointerPositionHandler}
        onPointerEnter={pointerEnterHandler}
        onPointerLeave={pointerLeaveHandler}
      >
        <Slider.Track
          ref={trackRef}
          className={classnames(styles.barSliderTrack, {
            [`${styles.barSliderTrackExpanded}`]: playerIsExpanded,
          })}
        >
          <PointerHoverBar
            visibility={isPointerOnBar ? 'visible' : 'hidden'}
            pointerPosition={getPointerPosition()}
          />
          <Slider.Range
            className={classnames(styles.barSliderRange, {
              [`${styles.barSliderRangeExpanded}`]: playerIsExpanded,
            })}
          />
        </Slider.Track>
        <Slider.Thumb
          className={classnames(styles.barSliderThumb, {
            [`${styles.barSliderThumbExpanded}`]: playerIsExpanded,
          })}
          aria-valuetext={useGetProgressToText(progression)}
        />
      </Slider.Root>
    </>
  );
};
