import React from 'react';
import styles from './PointerHoverBar.module.scss';
import { numberToPercentage } from 'utils/ui-helpers';

type Props = {
  pointerPosition: number;
  visibility: 'visible' | 'hidden';
};

export const PointerHoverBar = ({ pointerPosition, visibility }: Props) => (
  <span
    className={styles.hoverBar}
    style={{
      width: numberToPercentage(pointerPosition),
      visibility,
    }}
  />
);
