import classnames from 'classnames';
import { Bar } from './Bar';
import styles from './ProgressBar.module.scss';
import React, { useState } from 'react';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { getFormattedTime, progressToString } from 'utils/time';
import { LiveProgressBar } from 'components/Player/AudioPlayerWrapper/ProgressBar/LiveProgressBar';

type Props = {
  playerIsExpanded: boolean;
  seek: (position: number) => void;
  progression: number;
  duration: number;
  broadcastStartDate?: Date | undefined;
  broadcastEndDate?: Date | undefined;
};

const ProgressBar = ({
  playerIsExpanded,
  seek: playerSeek,
  progression,
  duration,
  broadcastStartDate,
  broadcastEndDate,
}: Props) => {
  const [position, setPosition] = useState(progression);
  const [isSeeking, setIsSeeking] = useState(false);
  const { activePlayer } = usePlayerState();

  const isRadioChannel = activePlayer?.item.type === 'RadioChannel';

  const seekEnded = (value: number) => {
    playerSeek(value);
    setIsSeeking(false);
  };

  const seek = (value: number) => {
    setPosition(value);
    setIsSeeking(true);
  };
  const currentPosition = isSeeking ? position : progression;

  const formattedBroadcastStartDate =
    broadcastStartDate && getFormattedTime(broadcastStartDate);

  const formattedBroadcastEndDate =
    broadcastEndDate && getFormattedTime(broadcastEndDate);

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.timeWrapper, {
          [`${styles.timeWrapperExpanded}`]: playerIsExpanded,
          [`${styles.timeWrapperLeft}`]: true,
        })}
      >
        {isRadioChannel ? (
          <time dateTime={broadcastStartDate?.toISOString()}>
            {formattedBroadcastStartDate}
          </time>
        ) : (
          <span>{progressToString(progression)}</span>
        )}
      </div>
      <div
        className={classnames(styles.progressBarContainer, {
          [`${styles.progressBarContainerExpanded}`]: playerIsExpanded,
        })}
      >
        {isRadioChannel ? (
          <LiveProgressBar />
        ) : (
          <Bar
            seek={seek}
            seekEnded={seekEnded}
            progression={currentPosition}
            duration={duration}
            playerIsExpanded={playerIsExpanded}
          />
        )}
      </div>
      <div
        className={classnames(styles.timeWrapper, {
          [`${styles.timeWrapperExpanded}`]: playerIsExpanded,
          [`${styles.timeWrapperRight}`]: true,
        })}
      >
        {isRadioChannel ? (
          <time dateTime={broadcastEndDate?.toISOString()}>
            {formattedBroadcastEndDate}
          </time>
        ) : (
          <span>{progressToString(duration)}</span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
