import { useFetchLastPlayedSongs } from 'components/Player/AudioPlayerWrapper/LastPlayedSongs/useFetchLastPlayedSongs';
import styles from './LastPlayedSongsList.module.scss';
import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { LastPlayedSong } from '@yleisradio/areena-types';
import { DialogTitle } from 'components/Dialog/Dialog';

const renderSongDetails = (song: LastPlayedSong) =>
  song.description ? (
    <span className={styles.rightSide}>
      <span className={styles.description}>{song.description}</span>
    </span>
  ) : (
    <span className={styles.rightSide}>
      <span className={styles.title}>{song.title}</span>{' '}
      <span className={styles.performer}>{song.performer}</span>
    </span>
  );

const LastPlayedSongsList = () => {
  const songsList = useFetchLastPlayedSongs();
  const t = useTranslation();

  return (
    <div className={styles.root}>
      <DialogTitle asChild>
        <h2 className={styles.heading}>{t('songs')}</h2>
      </DialogTitle>

      {songsList?.map((song) => (
        <div
          key={`${song.time?.formatted}${song.performer}${song.title}${song.description}`}
          className={styles.songContainer}
        >
          <time dateTime={song.time?.raw} className={styles.time}>
            {song.time?.formatted}
          </time>{' '}
          {renderSongDetails(song)}
        </div>
      ))}
    </div>
  );
};

export { LastPlayedSongsList };
