import { Card } from '@yleisradio/areena-types';
import React from 'react';
import styles from './OnDemandCardContent.module.scss';

type Props = {
  titleId: string;
  card: Card | undefined;
};

export const OnDemandCardContent = ({ titleId, card }: Props) => {
  return (
    <div className={styles.text}>
      <p className={styles.title} id={titleId}>
        {card?.title}
      </p>
      <p className={styles.description}>{card?.description}</p>
    </div>
  );
};
