import dynamic from 'next/dynamic';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { VideoPlayerWrapper } from './VideoPlayerWrapper';
import { useChatControl } from './useChatControl';
import { useSeekParameter } from './useSeekParameter';
import { YlePlayer } from './YlePlayer';
import { AudioPlayerWrapper } from './AudioPlayerWrapper';
import { YlePlayer as YlePlayerType } from 'types/player-static';
import * as Duration from 'iso8601-duration';
import {
  useAudioPlayerProgression,
  useAudioPlayerVolume,
  usePlaybackRate,
} from './AudioPlayerWrapper/hooks';

const Chat = dynamic(() => import('./Chat'));

export const Player: React.FunctionComponent = () => {
  const { activePlayer, setActivePlayer } = usePlayerState();
  const playerInstanceRef = useRef<YlePlayerType>();

  const mediaType = activePlayer?.media.type;

  const { progression, audioSeek, handleOnTimeUpdate } =
    useAudioPlayerProgression(playerInstanceRef);

  const volumeProps = useAudioPlayerVolume(playerInstanceRef.current);

  const { getPlaybackRates, setPlaybackRate, getPlaybackRate } =
    usePlaybackRate(playerInstanceRef.current);

  const closePlayer = useCallback(() => {
    setActivePlayer(undefined);
  }, [setActivePlayer]);

  const { control: chatControl } = useChatControl(activePlayer);
  const [isChatOpen, setChatOpenState] = useState(true);
  const closeChat = useCallback(() => {
    setChatOpenState(false);
  }, [setChatOpenState]);
  const openChat = useCallback(() => {
    setChatOpenState(true);
  }, [setChatOpenState]);

  // Return the chat to open state when player is closed
  useEffect(() => {
    if (!activePlayer && !isChatOpen) {
      setChatOpenState(true);
    }
  }, [activePlayer, isChatOpen, setChatOpenState]);

  const seek = useSeekParameter(activePlayer);

  const duration = activePlayer?.media.duration
    ? Duration.toSeconds(Duration.parse(activePlayer.media.duration))
    : 0;

  const chatElement =
    chatControl && isChatOpen ? (
      <Chat closeChat={closeChat} control={chatControl} />
    ) : null;

  const playerElement = activePlayer ? (
    <YlePlayer
      activePlayer={activePlayer}
      close={closePlayer}
      isChatAvailable={!!chatControl}
      isChatOpen={isChatOpen}
      openChat={openChat}
      seek={seek}
      onAudioTimeUpdate={handleOnTimeUpdate}
      playerInstanceRef={playerInstanceRef}
    />
  ) : null;

  switch (mediaType) {
    case 'AudioObject':
      return (
        <AudioPlayerWrapper
          playerElement={playerElement}
          progression={progression}
          duration={duration}
          seek={audioSeek}
          isMuted={volumeProps.isMuted}
          setMute={volumeProps.setMute}
          volume={volumeProps.volume}
          setVolume={volumeProps.setVolume}
          getPlaybackRates={getPlaybackRates}
          setPlaybackRate={setPlaybackRate}
          getPlaybackRate={getPlaybackRate}
        />
      );

    case 'VideoObject':
      return (
        <VideoPlayerWrapper
          closePlayer={closePlayer}
          chatElement={chatElement}
          playerElement={playerElement}
        />
      );

    case undefined:
      return null;

    default:
      throw new Error(`Player object has media of unknown type "${mediaType}"`);
  }
};
