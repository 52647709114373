import React, { useEffect } from 'react';
import IconPlayAudio from 'assets/playAudioIcon.svg';
import IconPauseAudio from 'assets/pauseAudioIcon.svg';
import IconReplay from 'assets/replay10Icon.svg';
import IconForward from 'assets/forward10Icon.svg';
import IconPrevious from 'assets/previousIcon.svg';
import IconNext from 'assets/nextIcon.svg';
import styles from './ButtonPlayerControl.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'hooks/useTranslation';

type Kind = 'play' | 'pause' | 'replay10' | 'forward10' | 'previous' | 'next';

type Props = {
  kind: Kind;
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  isButtonLarge?: boolean;
  isElevated?: boolean;
  disabled?: boolean;
  focusOnMount?: boolean;
};

type PropsIcon = {
  kind: Kind;
};

const Icon: React.FC<PropsIcon> = ({ kind }) => {
  const propsSvg = {
    width: '100%',
    height: '100%',
  };

  switch (kind) {
    case 'forward10':
      return <IconForward {...propsSvg} />;
    case 'pause':
      return <IconPauseAudio {...propsSvg} />;
    case 'play':
      return <IconPlayAudio {...propsSvg} />;
    case 'replay10':
      return <IconReplay {...propsSvg} />;
    case 'previous':
      return <IconPrevious {...propsSvg} />;
    case 'next':
      return <IconNext {...propsSvg} />;
    default:
      return null;
  }
};

export const ButtonPlayerControl: React.FC<Props> = ({
  onClick,
  kind,
  isButtonLarge = false,
  isElevated = false,
  disabled = false,
  focusOnMount,
}) => {
  const t = useTranslation();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnMount && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [focusOnMount]);

  return (
    <button
      ref={buttonRef}
      className={classNames(
        styles.root,
        styles[kind],
        isButtonLarge && styles.large,
        isElevated && styles.elevated,
        disabled && styles.disabled
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      disabled={disabled}
      aria-label={t(kind)}
    >
      <Icon kind={kind} />
    </button>
  );
};
