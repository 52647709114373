import { useCallback, useEffect, useState } from 'react';
import { YlePlayer } from 'types/player-static';
import {
  PlaybackRateGetter,
  PlaybackRateSetter,
  PlaybackRatesGetter,
} from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';
import logger from 'services/logger';

export function useIsExpandedPlayerAvailable(): boolean {
  const [state, setState] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('screen and (min-width: 800px)');

    setState(!mediaQuery.matches);

    const onChange = (e: MediaQueryListEvent) => {
      setState(!e.matches);
    };

    mediaQuery.addEventListener('change', onChange);

    return () => {
      mediaQuery.removeEventListener('change', onChange);
    };
  }, []);

  return state;
}

export const useAudioPlayerProgression = (
  playerInstanceRef: React.MutableRefObject<YlePlayer | undefined>
) => {
  const [progression, setProgression] = useState(0);

  const audioSeek = useCallback(
    (position: number) => {
      playerInstanceRef.current?.seek?.(position);
      setProgression(position);
    },
    [playerInstanceRef]
  );

  const handleOnTimeUpdate = useCallback((time: number) => {
    const position = Math.floor(time);

    setProgression(position);
  }, []);

  return { progression, audioSeek, handleOnTimeUpdate };
};

export const useAudioPlayerVolume = (
  playerInstance: YlePlayer | undefined
): {
  isMuted: boolean;
  setMute: (mute: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
} => {
  const [muteState, setMuteState] = useState(false);
  const [volumeState, setVolumeState] = useState(1);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    try {
      const storageValue = window.localStorage.getItem('PlayerUI:settings');

      if (!storageValue) return;

      const playerSettings = JSON.parse(storageValue);

      if (playerSettings.volume) {
        setVolumeState(playerSettings.volume);
      }
      if (playerSettings.muted || playerSettings.volume === 0) {
        setMuteState(true);
      }
    } catch (error) {
      logger.error(
        error,
        'Cannot read playerUI volume value from local storage'
      );
    }
  }, []);

  const setMute = useCallback(
    (mute: boolean): void => {
      if (!playerInstance)
        throw new Error(
          "Can't set mute state because playerInstance is undefined"
        );

      setMuteState(mute);

      if (mute) {
        if (!playerInstance.mute) throw new Error('player.mute is undefined');

        playerInstance.mute();
      } else {
        if (!playerInstance.unmute)
          throw new Error('player.unmute is undefined');

        playerInstance.unmute();
      }
    },
    [playerInstance]
  );

  const setVolume = useCallback(
    (volume: number): void => {
      if (!playerInstance)
        throw new Error("Can't set volume because playerInstance is undefined");
      if (!playerInstance.volume) throw new Error('player.volume is undefined');

      setVolumeState(volume);
      playerInstance.volume(volume);
    },
    [playerInstance]
  );

  return {
    isMuted: muteState,
    setMute,
    volume: volumeState,
    setVolume,
  };
};

export const usePlaybackRate = (playerInstance: YlePlayer | undefined) => {
  const [currentPlaybackRates, setCurrentPlaybackRates] = useState<
    number[] | undefined
  >();
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState<number>(1);

  useEffect(() => {
    if (playerInstance && playerInstance.playbackRates) {
      setCurrentPlaybackRates(playerInstance.playbackRates());
    }
  }, [playerInstance]);

  const setPlaybackRate: PlaybackRateSetter = useCallback(
    (rate: number) => {
      if (!playerInstance) {
        throw new Error(
          "Can't set playback rate because playerInstance is undefined"
        );
      }
      if (!playerInstance.setPlaybackRate) {
        throw new Error('player.setPlaybackRate is undefined');
      }

      playerInstance.setPlaybackRate(rate);
      setCurrentPlaybackRate(rate);
    },
    [playerInstance]
  );

  const getPlaybackRate: PlaybackRateGetter = () => {
    return currentPlaybackRate;
  };

  const getPlaybackRates: PlaybackRatesGetter = () => {
    return currentPlaybackRates;
  };

  return {
    getPlaybackRates,
    setPlaybackRate,
    getPlaybackRate,
  };
};
