import React from 'react';
import styles from 'components/Player/AudioPlayerWrapper/PopOverButton.module.scss';
import classNames from 'classnames';

type Props = {
  togglePlayQueue: () => void;
  size?: 'small' | 'extra-small';
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  buttonText: string;
};

const PopOverButton = ({
  togglePlayQueue,
  size = 'extra-small',
  Icon,
  buttonText,
}: Props) => {
  const className = classNames(styles.button, styles[size]);

  return (
    <button className={className} onClick={togglePlayQueue}>
      <span className={styles.buttonText}>{buttonText}</span>
      {Icon && <Icon aria-hidden className={styles.icon} />}
    </button>
  );
};

export default PopOverButton;
