import * as Switch from '@radix-ui/react-switch';
import styles from './Toggle.module.scss';
import { FC } from 'react';

type Props = {
  id: string;
  checkedValue: boolean;
  onCheckedChangeFunction: (value: boolean) => void;
};

export const Toggle: FC<Props> = ({
  id,
  checkedValue,
  onCheckedChangeFunction,
}) => {
  return (
    <Switch.Root
      className={styles.switchRoot}
      id={id}
      checked={checkedValue}
      onCheckedChange={onCheckedChangeFunction}
    >
      <Switch.Thumb className={styles.switchThumb} />
    </Switch.Root>
  );
};
