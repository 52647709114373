import { useRouter } from 'next/router';
import React from 'react';

type Props = { children: React.ReactElement | null };
export const PlayerDebugWrapper: React.FC<Props> = (props) => {
  const router = useRouter();

  if ('audioPlayerDebug' in router.query) {
    return (
      <div
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          color: 'black',
          border: '5px solid red',
          maxWidth: '40rem',
          height: '4rem',
          bottom: '10rem',
          left: '5px',
          right: '5px',
        }}
      >
        {props.children}
      </div>
    );
  }

  return <div hidden>{props.children}</div>;
};
