import { Image as ImageType } from '@yleisradio/areena-types/domain/image';
import styles from './BackgroundImage.module.scss';
import Image from 'next/image';
import { backgroundImageLoader, loaderUrl } from 'utils/cloudinary';
import React from 'react';

type BackgroundImageProps = {
  image: ImageType | undefined;
};

const BackgroundImage = ({ image }: BackgroundImageProps) => {
  if (!image) return null;
  return (
    <>
      <div className={styles.backgroundImage}>
        <Image
          key={image?.id}
          className={styles.image}
          src={loaderUrl(image)}
          fill
          alt=""
          role="presentation"
          sizes="100vw"
          loader={backgroundImageLoader({
            aspectRatio: '1:1',
            isBlurred: true,
          })}
        />
      </div>
      <div className={styles.overlay} />
    </>
  );
};

export { BackgroundImage };
