import { FunctionComponent } from 'react';
import styles from './PlaceholderSimpleCard.module.scss';

export const PlaceholderSimpleCard: FunctionComponent = () => {
  return (
    <div className={styles.placeholderCard}>
      <div className={styles.checkBox}></div>
      <div className={styles.textWrapper}>
        <div className={styles.title}></div>
        <div className={styles.description}></div>
      </div>
    </div>
  );
};
