import { Card } from 'components/Card';
import { Card as CardType, ListStyle } from '@yleisradio/areena-types';
import { useProgress } from 'hooks/useProgress';
import React from 'react';
import { getHistoryLabelsForCard } from 'utils/card';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { useFetchQueueCard } from 'components/Player/AudioPlayerWrapper/useFetchQueueCard';
import { SimpleCard } from './SimpleCard/SimpleCard';
import { QueueItem } from '../../../../contexts/PlayerStateContext/QueueItem';
import { PlaceholderSimpleCard } from 'components/PlaceholderSimpleCard';

type Props = {
  item: QueueItem;
  isActive: boolean;
  isSortable?: boolean;
  isCheckboxSelected: boolean;
  isSimpleCard?: boolean;
  setCheckboxSelected: (value: boolean) => void;
};

const sizes =
  '(min-width: 1920px) 18vw, (min-width: 1020px) 17vw, (min-width: 640px) 21vw, 35vw';

const listStyle: ListStyle = {
  layout: 'vertical',
  image: '1:1',
  size: 'medium',
};

type QueueCardProps = {
  cardKey: string;
  isActive: boolean;
  card: CardType;
};

const QueueCard = ({ card, cardKey, isActive }: QueueCardProps) => {
  const { historyEntries } = useProgress([card]);
  const historyLabels = getHistoryLabelsForCard(card, historyEntries);

  return (
    <Card
      card={card}
      cardKey={cardKey}
      sizes={sizes}
      listStyle={listStyle}
      historyLabels={historyLabels}
      isActive={isActive}
      isDescriptionVisible={true}
      hasEpisodeTitle={true}
    />
  );
};

const PlayQueueCard = ({
  item,
  isActive = false,
  isSortable = false,
  isSimpleCard = false,
  isCheckboxSelected,
  setCheckboxSelected,
}: Props) => {
  const cardByItemId = useFetchQueueCard(item.programId);

  if (isSimpleCard) {
    if (!cardByItemId) {
      return <PlaceholderSimpleCard />;
    }

    return (
      <SimpleCard
        card={cardByItemId}
        cardKey={`play-queue-card-${item.key}`}
        isCheckboxSelected={isCheckboxSelected}
        isSortable={isSortable}
        setCheckboxSelected={setCheckboxSelected}
        queueKey={item.key}
      />
    );
  }

  if (!cardByItemId) {
    return <PlaceholderCard listStyle={listStyle} />;
  }

  return (
    <QueueCard card={cardByItemId} cardKey={item.key} isActive={isActive} />
  );
};

export { PlayQueueCard, QueueCard };
