import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { QueueItem } from '../../../../contexts/PlayerStateContext/QueueItem';
import { Button } from 'components/Button';

type Props = {
  items: QueueItem[];
  selectedItemKeys: string[];
  setItemSelection: (selected: boolean, itemKey: string) => void;
  removeAllQueueItems: () => void;
};

const NextInQueueList = ({
  items,
  selectedItemKeys,
  setItemSelection,
  removeAllQueueItems,
}: Props) => {
  const t = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.queueHeaderRow}>
        <h3 className={styles.playQueueSubTitle}>{t('nextInQueue')}</h3>
        <div className={styles.emptyQueueButtonWrapper}>
          <Button
            text={t('emptyQueue')}
            onClick={removeAllQueueItems}
            variant="text"
            size="xs"
            padding="none"
          />
        </div>
      </div>
      {items &&
        items.map((item) => {
          return (
            <div key={item.key} className={styles.cardContainer}>
              <PlayQueueCard
                item={item}
                isActive={false}
                isSortable={true}
                isSimpleCard={true}
                isCheckboxSelected={selectedItemKeys.includes(item.key)}
                setCheckboxSelected={(selected) => {
                  setItemSelection(selected, item.key);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default NextInQueueList;
