import React from 'react';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { CurrentlyPlayingCard } from '../CurrentlyPlayingCard';
import { PlayerProps } from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';
import styles from './ExpandableControls.module.scss';
import { ButtonPlayerControl } from '../../ButtonPlayerControl';

export const ExpandableControls = ({
  ongoingCard,
  upcomingCard,
  progression,
  duration,
  seek,
  isPlayerExpanded,
  togglePlay,
  isPlaying,
  isRadioChannel,
}: PlayerProps) => {
  return (
    <>
      <ProgressBar
        progression={progression}
        duration={duration}
        seek={seek}
        playerIsExpanded={isPlayerExpanded}
      />
      <div className={styles.controls}>
        <CurrentlyPlayingCard
          ongoingCard={ongoingCard}
          upcomingCard={upcomingCard}
          isRadioChannel={isRadioChannel}
        />

        <ButtonPlayerControl
          kind={isPlaying ? 'pause' : 'play'}
          onClick={togglePlay}
          isElevated={true}
        />
      </div>
    </>
  );
};
