import React, { useCallback, useState } from 'react';

import {
  DropdownMenu,
  Trigger,
  RadioGroup,
  RadioItem,
  Content,
  Label,
  Portal,
  ItemIndicator,
} from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'hooks/useTranslation';
import styles from './MoreDropdownMenu.module.scss';
import MoreVerticalIcon from 'assets/moreVerticalIcon.svg';
import {
  PlaybackRateGetter,
  PlaybackRateSetter,
  PlaybackRatesGetter,
} from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';
import { useEscHandler } from 'contexts/EscHandlerContext/EscHandlerContext';
import { useUILanguage } from 'hooks/useUILanguage';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

const NORMAL_RATE = 1;

const Rate = ({ rate }: { rate: number }) => {
  const language = useUILanguage();
  const t = useTranslation();

  return (
    <RadioItem className={styles.rate} value={String(rate)}>
      <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}>
          <ItemIndicator className={styles.checked} />
        </div>
      </div>
      {rate === NORMAL_RATE
        ? t('playbackRateNormal')
        : `${rate.toLocaleString(language)}x`}
    </RadioItem>
  );
};

type Props = {
  getPlaybackRates: PlaybackRatesGetter;
  getPlaybackRate: PlaybackRateGetter;
  setPlaybackRate: PlaybackRateSetter;
};

const Menu = ({
  getPlaybackRates,
  getPlaybackRate,
  setPlaybackRate,
}: Props) => {
  const playbackRates = getPlaybackRates();
  const playbackRate = getPlaybackRate();
  const t = useTranslation();

  const { addEventHandler, removeEventHandler } = useEscHandler();
  const eventHandlerTag = 'dropdown-menu';

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      removeEventHandler(eventHandlerTag);
    } else {
      addEventHandler(() => setMenuOpen(false), eventHandlerTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen, setMenuOpen]);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={toggleMenu} modal={false}>
      <Trigger className={styles.trigger}>
        <VisuallyHidden>{t('playbackRate')}</VisuallyHidden>
        <MoreVerticalIcon className={styles.triggerIcon} />
      </Trigger>
      <Portal>
        <Content
          className={styles.content}
          align="end"
          side="top"
          onEscapeKeyDown={(event) => event.preventDefault()}
        >
          <Label className={styles.label}>{t('playbackRate')}</Label>
          <RadioGroup
            className={styles.radioGroup}
            value={String(playbackRate)}
            onValueChange={(value) => setPlaybackRate(Number(value))}
          >
            {playbackRates?.map((rate) => <Rate key={rate} rate={rate} />)}
          </RadioGroup>
        </Content>
      </Portal>
    </DropdownMenu>
  );
};

export default Menu;
