import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { Card } from '@yleisradio/areena-types';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { getCardKey } from 'utils/card';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { Labels } from 'services/yleAnalyticsSdk';
import { idFromPointer } from 'utils/pointer';

type Props = {
  heading: string;
  cards: Card[];
  analyticsContext: Labels | undefined;
  listKey: string;
  loading?: boolean;
  handleCheckboxChange: (checked: boolean, index: number) => void;
  selectedCardIndexes: number[];
};

const NextInSeriesList = ({
  heading,
  cards,
  analyticsContext,
  listKey,
  handleCheckboxChange,
  selectedCardIndexes,
}: Props) => {
  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <h3 className={styles.subTitle}>{heading}</h3>
      {cards &&
        cards.map((card, i) => {
          const cardKey = getCardKey(card, listKey);
          const itemId = idFromPointer(card?.pointer) || '';
          const item = { key: '', programId: itemId };
          return (
            <div key={cardKey} className={styles.cardContainer}>
              <PlayQueueCard
                isActive={false}
                item={item}
                isSimpleCard={true}
                setCheckboxSelected={(value) => handleCheckboxChange(value, i)}
                isCheckboxSelected={selectedCardIndexes.includes(i)}
              />
            </div>
          );
        })}
    </AnalyticsContextProvider>
  );
};

export default NextInSeriesList;
