import React from 'react';
import { numberToPercentage } from 'utils/ui-helpers';
import styles from './PointerPositionTimePreview.module.scss';
import classnames from 'classnames';

type Props = {
  visibility: 'visible' | 'hidden';
  pointerPosition: number;
  pointerTime: string;
  playerIsExpanded: boolean;
};

export const PointerPositionTimePreview = ({
  visibility,
  pointerPosition,
  pointerTime,
  playerIsExpanded,
}: Props) => (
  <div
    className={classnames(styles.pointerTimeWrapper, {
      [`${styles.pointerTimeWrapperExpanded}`]: playerIsExpanded,
    })}
  >
    <span
      style={{
        left: `${numberToPercentage(pointerPosition)}`,
        visibility,
      }}
      className={classnames(styles.pointerTime, {
        [`${styles.pointerTimeExpanded}`]: playerIsExpanded,
      })}
    >
      {pointerTime}
    </span>
  </div>
);
